


















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();

@Component
export default class FAQs extends Vue {}
